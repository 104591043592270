import { invokeApi } from "../../bl_libs/invokeApi";
export const addStripeApi = async (data) => {
  const requestObj = {
    path: `api/site_setting/add_stripe_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addCampaignApi = async (data) => {
  const requestObj = {
    path: `api/site_setting/add_active_compaign_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addZoomSettingApi = async (data) => {
  const requestObj = {
    path: `api/site_setting/zoom_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addSandGrid = async (data) => {
  const requestObj = {
    path: `api/site_setting/add_sendgrid_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const siteSettingApi = async (data) => {
  const requestObj = {
    path: `api/site_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const defaultSettingApi = async (id) => {
  const requestObj = {
    path: `api/coach_configuration/detail_coach_configuration/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const consultantDataApi = async (data) => {
  const requestObj = {
    path: `api/coach_configuration/get_coach_configuration`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addDefaultSettingApi = async (data, coach_id) => {
  const requestObj = {
    path: `api/coach_configuration/update_coach_configuration_meta_data/${coach_id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};
export const addInvoiceSettingApi = async (data) => {
  const requestObj = {
    path: `api/project_configuration/update_invoice`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};
export const emailTemplateApi = async (id) => {
  const requestObj = {
    path: `api/email_template/coach_email_template_list/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const editEmailTemplateApi = async (data, id) => {
  const requestObj = {
    path: `api/email_template/update_email_template/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};
export const detailEmailTemplateApi = async (id) => {
  const requestObj = {
    path: `api/email_template/get_email_template_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const deleteEmailTemplateApi = async (id) => {
  const requestObj = {
    path: `api/email_template/delete_email_template/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const tagsApi = async () => {
  const requestObj = {
    path: `api/email_template/get_email_tags/coach`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
