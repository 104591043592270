import { invokeApi } from "src/bl_libs/invokeApi";
import { baseMultilanguageUri } from "src/config/config";

export const get_coach_language_list = async (id) => {
  const requestObj = {
    path: `api/content_configuration/get_coach_language_list_for_dashboard/${id}`,
    method: "GET",
    multilanguage_baseuri: baseMultilanguageUri,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_coach_admin_language_listing = async (id) => {
  const requestObj = {
    path: `api/content_configuration/get_coach_language_list/${id}`,
    method: "GET",
    multilanguage_baseuri: baseMultilanguageUri,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_coach_default_language = async (id, lang) => {
  const requestObj = {
    path: `api/coach_configuration/update_default_language/${id}/${lang}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_coach_languages = async (id, data) => {
  const requestObj = {
    path: `api/content_configuration/update_coach_language/${id}`,
    method: "PUT",
    multilanguage_baseuri: baseMultilanguageUri,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_coach_client_language_detail = async (id, lang) => {
  const requestObj = {
    path: `api/content_configuration/get_coach_language_detail/${id}/${lang}`,
    method: "GET",
    multilanguage_baseuri: baseMultilanguageUri,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_client_dashboard_content = async (data, id) => {
  const requestObj = {
    path: `api/content_configuration/update_client_dashboard/${id}`,
    method: "PUT",
    multilanguage_baseuri: baseMultilanguageUri,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_client_affiliate_pages_content = async (data, id) => {
  const requestObj = {
    path: `api/content_configuration/update_client_affiliate_pages/${id}`,
    method: "PUT",
    multilanguage_baseuri: baseMultilanguageUri,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_client_recordings_content = async (data, id) => {
  const requestObj = {
    path: `api/content_configuration/update_client_recordings/${id}`,
    method: "PUT",
    multilanguage_baseuri: baseMultilanguageUri,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_client_calendar_content = async (data, id) => {
  const requestObj = {
    path: `api/content_configuration/update_client_calendar/${id}`,
    method: "PUT",
    multilanguage_baseuri: baseMultilanguageUri,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_client_pods_content = async (data, id) => {
  const requestObj = {
    path: `api/content_configuration/update_client_pods/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_client_right_side_navs_content = async (data, id) => {
  const requestObj = {
    path: `api/content_configuration/update_client_portal_right_side/${id}`,
    method: "PUT",
    multilanguage_baseuri: baseMultilanguageUri,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_client_profile_popover_content = async (data, id) => {
  const requestObj = {
    path: `api/content_configuration/update_client_profile_popover/${id}`,
    method: "PUT",
    multilanguage_baseuri: baseMultilanguageUri,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_client_breadcrumbs_content = async (data, id) => {
  const requestObj = {
    path: `api/content_configuration/update_client_breadcrumbs/${id}`,
    method: "PUT",
    multilanguage_baseuri: baseMultilanguageUri,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_client_programmes_content = async (data, id) => {
  const requestObj = {
    path: `api/content_configuration/update_client_prgrammes/${id}`,
    method: "PUT",
    multilanguage_baseuri: baseMultilanguageUri,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_client_portal_content = async (data, id) => {
  const requestObj = {
    path: `api/content_configuration/update_client_portal/${id}`,
    method: "PUT",
    multilanguage_baseuri: baseMultilanguageUri,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_client_community_content = async (data, id) => {
  const requestObj = {
    path: `api/content_configuration/update_client_community/${id}`,
    method: "PUT",
    multilanguage_baseuri: baseMultilanguageUri,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_client_support_ticket_content = async (data, id) => {
  const requestObj = {
    path: `api/content_configuration/update_client_support_ticket/${id}`,
    method: "PUT",
    multilanguage_baseuri: baseMultilanguageUri,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
