const _get_user_from_localStorage = () => {
  const user_profile = localStorage.getItem("user_data");
  if (
    user_profile &&
    user_profile !== undefined &&
    user_profile !== "undefined" &&
    user_profile !== null
  ) {
    return JSON.parse(localStorage.getItem("user_data"));
  } else {
    return {};
  }
};
const _get_general_data_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("general_data"));
};

const _get_consultant_data_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("consultantdata"));
};
const _get_dynamic_caoch_id_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("dynamic_coach_id"));
};
const _get_coach_id_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("coachId"));
};
const _get_nav_items_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("navItems"));
};
const _get_coach_list_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("coachList"));
};

const _get_project_data_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("project_data"));
};
const _get_domain_data_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("domain_data"));
};
const _get_Consultant_Nav_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("user_data"));
};
const _get_card_localStorage = () => {
  return JSON.parse(localStorage.getItem("card_data"));
};
const _get_content_setting_localStorage = () => {
  return JSON.parse(localStorage.getItem("content_setting"));
};
const _get_coach_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("coach_data"));
};
const _get_consultant_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("consultant_data"));
};
const _get_client_dashboard_setting_localStorage = () => {
  return JSON.parse(localStorage.getItem("client_dashboard_setting"));
};
const _get_testmonial_localStorage = () => {
  return JSON.parse(localStorage.getItem("testmonial"));
};
const _get_home_event_localStorage = () => {
  return JSON.parse(localStorage.getItem("home_event"));
};
const _get_admin_time_zone_localStorage = () => {
  return JSON.parse(localStorage.getItem("admin_time_zone"));
};
const _get_coach_time_zone_localStorage = () => {
  return JSON.parse(localStorage.getItem("coach_time_zone"));
};
const get_project_info_localStorage = () => {
  return JSON.parse(localStorage.getItem("project_info"));
};

const _set_project_info_localStorage = (data) => {
  return localStorage.setItem("project_info", JSON.stringify(data));
};

const get_admin_data_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("admin_data"));
};

const set_admin_data_in_localStorage = (data) => {
  return localStorage.setItem("admin_data", JSON.stringify(data));
};

const set_graphics_data_in_localStorage = (data) => {
  return localStorage.setItem("coach_graphics_data", JSON.stringify(data));
};

const _get_consultant_id_from_localStorage = () => {
  return localStorage.getItem("coach_id");
};

const _get_team_type_from_localStorage = () => {
  return localStorage.getItem("team_type");
};

const _get_graphics_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("coach_graphics_data"));
};

const _get_default_content_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("defaultContent"));
};

const _get_defaultLangugae_from_localStorage = () => {
  return localStorage.getItem("defaulLanguagae");
};

module.exports = {
  _get_coach_list_from_localStorage,
  _get_nav_items_from_localStorage,
  _get_general_data_from_localStorage,
  _get_consultant_data_from_localStorage,
  _get_coach_id_from_localStorage,
  _get_project_data_from_localStorage,
  _get_domain_data_from_localStorage,
  _get_coach_from_localStorage,
  _get_user_from_localStorage,
  _get_card_localStorage,
  _get_content_setting_localStorage,
  _get_client_dashboard_setting_localStorage,
  _get_testmonial_localStorage,
  _get_home_event_localStorage,
  _get_admin_time_zone_localStorage,
  _get_coach_time_zone_localStorage,
  _get_consultant_from_localStorage,
  get_project_info_localStorage,
  _set_project_info_localStorage,
  _get_Consultant_Nav_from_localStorage,
  _get_graphics_from_localStorage,
  set_admin_data_in_localStorage,
  get_admin_data_from_localStorage,
  _get_consultant_id_from_localStorage,
  _get_team_type_from_localStorage,
  set_graphics_data_in_localStorage,
  _get_dynamic_caoch_id_from_localStorage,
  _get_default_content_from_localStorage,
  _get_defaultLangugae_from_localStorage,
};
