import React, { useContext, useEffect, useState } from "react";
import { baseUri, socketBaseUri } from "src/config/config";
import {
  _get_admin_time_zone_localStorage,
  _get_card_localStorage,
  _get_coach_from_localStorage,
  _get_consultant_from_localStorage,
  _get_consultant_id_from_localStorage,
  _get_team_type_from_localStorage,
  _get_user_from_localStorage,
  _get_graphics_from_localStorage,
  set_graphics_data_in_localStorage,
  _get_general_data_from_localStorage,
  _get_consultant_data_from_localStorage,
  _get_coach_id_from_localStorage,
  _get_project_data_from_localStorage,
  _get_dynamic_caoch_id_from_localStorage,
  _get_nav_items_from_localStorage,
  _get_coach_list_from_localStorage,
  _get_domain_data_from_localStorage,
  _get_coach_time_zone_localStorage,
  _get_default_content_from_localStorage,
  _get_defaultLangugae_from_localStorage,
} from "../DAL/localstorage/LocalStorage";

import io from "socket.io-client";
import { consultantProfileApi } from "src/DAL/Login/Login";
import { getDefaultNavProgram } from "src/DAL/WebsitePages/WebsiteProgrammes";
import { consultantDataApi } from "src/DAL/SiteSetting/siteSetting";
import { project_configuration_api } from "src/DAL/WebsitePages/WebsitePages";
import moment from "moment";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { currencyApi } from "src/DAL/WebsitePages/paymentPlan";
import { get_coach_language_list } from "src/DAL/Languages/Languages";

const CreateContentSetting = React.createContext();
const get_admin_time_zone_localStorage = _get_admin_time_zone_localStorage();
const get_coach_time_zone_localStorage = _get_coach_time_zone_localStorage();
const get_user = _get_user_from_localStorage();
const get_card = _get_card_localStorage();
const get_consultant = _get_consultant_from_localStorage();
const get_coach = _get_coach_from_localStorage();
const get_consultant_id = _get_consultant_id_from_localStorage();
const get_team_type = _get_team_type_from_localStorage();
const coach_graphics_data = _get_graphics_from_localStorage();
const get_general_data = _get_general_data_from_localStorage();
const get_consultant_data = _get_consultant_data_from_localStorage();
const get_coach_id = _get_coach_id_from_localStorage();
const get_project_data = _get_project_data_from_localStorage();
const get_domain_data = _get_domain_data_from_localStorage();
const get_dynamic_coach = _get_dynamic_caoch_id_from_localStorage();
const get_nav_items = _get_nav_items_from_localStorage();
const get_coach_list = _get_coach_list_from_localStorage();
const get_default_content = _get_default_content_from_localStorage();
const get_default_language = _get_defaultLangugae_from_localStorage();
// socket-initialization
const socketConfig = {
  reconnectionDelayMax: 10000,
};

let socket = { on: () => {}, emit: () => {}, off: () => {} };
if (get_consultant_data?.coach._id) {
  // toturn off the socket
  socket = io(
    socketBaseUri + `?user_id=${get_consultant_data.coach._id}&role=coach`,
    {
      ...socketConfig,
    }
  );
}

export const useContentSetting = () => useContext(CreateContentSetting);
export function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */

  const START_SOCKET_FORCEFULLY = (user_id) => {
    if (Object.keys(socket).length > 0) {
      // check if the connection request from same user id
      let uri = socket.io?.uri;
      let is_same_user = uri?.includes(user_id);

      // --------------------------------------------------------------
      // if socket is not connected. Just connect the socket with user id

      if (!socket.connected) {
        socket = io(
          socketBaseUri + `?user_id=${user_id}&role=coach`,
          socketConfig
        );
        setIsConnected(true);
        return;
      }

      // --------------------------------------------------------------

      // if socket is connected but upcoming user id is for new user
      // close old connection and open new connection with new id.

      if (socket.connected && !is_same_user) {
        socket.disconnect();
        socket = io(
          socketBaseUri + `?user_id=${user_id}&role=coach`,
          socketConfig
        );

        setIsConnected(true);
      }

      // --------------------------------------------------------------
    } else {
      socket = io(
        socketBaseUri + `?user_id=${user_id}&role=coach`,
        socketConfig
      );
    }
  };

  const STOP_SOCKET_FORCEFULLY = (user_id) => {
    socket.disconnect();
  };

  const [adminTimeZone, setAdminTimeZone] = useState(
    get_admin_time_zone_localStorage
  );
  const [coachTimeZone, setCoachTimeZone] = useState(
    get_coach_time_zone_localStorage
  );
  const [generalData, setGeneralData] = useState(get_general_data);
  const [consultantData, setConsultantData] = useState(get_consultant_data);
  const [dynamicCoachId, setDynamicCoachId] = useState(get_dynamic_coach);
  const [coachId, setCoachId] = useState(get_coach_id);
  const [coachGraphics, setCoachGraphics] = useState(coach_graphics_data);
  const [projectData, setProjectData] = useState(get_project_data);
  const [domainData, setDoaminData] = useState(get_domain_data);
  const [stripeKeysExist, setStripeKeysExist] = useState(false);

  const [navItems, setNavItems] = useState(get_nav_items);
  const [coachList, setCoachList] = useState(get_coach_list);

  //
  const [userInfo, setUserInfo] = useState(get_consultant_data?.coach);
  const [cardDetail, setCardDetail] = useState(get_card);

  const [consultantInfo, setConsultantInfo] = useState(get_consultant);
  const [coachInfo, setCoachInfo] = useState(get_coach);
  const [consultantId, setConsultantId] = useState(get_consultant_id);
  const [teamType, setTeamType] = useState(get_team_type);
  const [teamCoachId, setTeamCoachId] = useState(get_user?.action_id);
  const [memberType, setMemberType] = useState(get_user?.team_type);
  const [currencyList, setCurrencyList] = useState([]);

  const [isConnected, setIsConnected] = useState(socket.connected);

  const [openCardModal, setOpenCardModal] = useState(false);
  const [open, setOpen] = useState(false); // state to handle notifiaction popup
  //dummy data for default content
  const [defaultContent, setDefaultContent] = useState(get_default_content);
  // const [defaultContent, setDefaultContent] = useState({
  //   community: {
  //     main_community: {
  //       left_community_section: {
  //         create_post_button_text: "Create Post",
  //         dashboard_tab_text: "Dashboard",
  //         approval_queue_tab_text: "Approval Queue",
  //         recent_posts_tab_text: "Recent Posts",
  //         pined_posts_tab_text: "Pined Posts",
  //         reported_posts_tab_text: "Reported Posts",
  //         trending_post_tab_text: "Trending Posts",
  //         manage_sidebar_sections_tab_text: "Manage Sidebar Sections",
  //         community_settings_tab_text: "Community Settings",
  //       },
  //       right_community_section: {
  //         today_button_text: "Today",
  //         last_seven_days_button_text: "Last 7 Days",
  //         last_thirty_days_button_text: "Last 30 Days",
  //         likes_card_title: "Likes",
  //         likes_card_decrease_text: "Decrease",
  //         likes_card_increase_text: "Increase",
  //         comments_card_title: "Comments",
  //         comment_card_decrease_text: "Decrease",
  //         comment_card_increase_text: "Increase",
  //         total_posts_card_title: "Total Posts",
  //         total_posts_decrease_text: "Decrease",
  //         total_posts_increase_text: "Increase",
  //         pined_posts_title: "Pined Posts",
  //         approval_queue_title: "Approval Queue",
  //         trending_posts_title: "Trending Posts",
  //         recent_posts_title: "Recent Posts",
  //         reported_posts_title: "Reported Posts",
  //         notification_card_title: "Notifications",
  //         recent_comments_card_title: "Recent Comments",
  //         unpin_posts_menu_option: "Unpin",
  //         delet_confirmation_text: "Are you sure you want to delete this Post?",
  //       },
  //       create_post_card: {
  //         create_post_button__text: "Create Post",
  //         create_post_heading: "Create Post",
  //         update_post_heading: "Update Post",
  //         whats_on_your_mind_placeholder: "What's on your mind,",
  //         general_feed_tooltip: "General Feed",
  //         image_feed_tooltip: "Image Feed",
  //         video_feed_tooltip: "Video Feed",
  //         embeded_code_feed_tooltip: "Embeded Feed",
  //         choose_background_color_tooltip: "Choose Background Color",
  //         add_photo_placeholder: "Add Photo",
  //         video_url_placeholder: "Video Url",
  //         embeded_code_feed_placeholder: "LiveStream Embeded Code",
  //         post_button_text: "Post",
  //         posting_button_text: "Posting...",
  //         cancel_button_text: "Cancel",
  //         update_button_text: "Update",
  //         updating_button_text: "Updating...",
  //       },
  //       share_your_thoughs_card: {
  //         share_your_thoughts_placeholder: "Share your thoughts,",
  //         photos_text: "Photos",
  //         videos_text: "Videos",
  //       },
  //       post_card: {
  //         edit_menu_text: "Edit",
  //         delete_menu_text: "Delete",
  //         pin_menu_text: "Pin",
  //         unpin_menu_text: "Unpin",
  //         message_menu_text: "Message",
  //         delete_confirmation_text:
  //           "Are you sure you want to delete this post?",
  //         see_more_button_text: "See More",
  //         see_less_button_text: "See Less",
  //         like_button_text: "Like",
  //         liked_button_text: "Liked",
  //         comment_button_text: "Comment",
  //       },
  //       feed_detail: {
  //         see_more_button_text: "See More",
  //         see_less_button_text: "See Less",
  //         like_button_text: "Like",
  //         liked_button_text: "Liked",
  //         comment_button_text: "Comment",
  //         and: "and",
  //         others: "others",
  //         write_a_comment_placeholder: "Write a comment…",
  //         edit_menu_text: "Edit",
  //         delete_menu_text: "Delete",
  //         comment_confirmation_text:
  //           "Are you sure you want to delete this comment?",
  //         comment_like_text: "Like",
  //         comment_liked_text: "Liked",
  //         comment_reply_text: "Reply",
  //         write_a_reply_placeholder: "Write a reply",
  //         single_comment_confirmation_menu_text:
  //           "Are you sure you want to delete this comment?",
  //       },
  //       section_listing: {
  //         title: "Section",
  //         add_section_button_text: "Add Section",
  //         action_table_head: "ACTION",
  //         icon_table_head: "ICON",
  //         section_title_table_head: "SECTION TITLE",
  //         position_table_head: "POSITION",
  //         order_table_head: "ORDER",
  //         status_table_head: "STATUS",
  //         active: "Active",
  //         inactive: "Inactive",
  //         edit_menu_option: "Edit",
  //         delete_menu_option: "Delete",
  //         section_items_menu_option: "Section Items",
  //         delete_confirmation_text:
  //           "Are you sure you want to delete this section?",
  //       },
  //       add_edit_section: {
  //         add_section_title: "Add Section",
  //         edit_section_title: "Edit Section",
  //         section_title_label: "Section Title",
  //         section_status_label: "Section Status",
  //         section_status_active: "Active",
  //         section_status_inactive: "Inactive",
  //         upload_icon_label: "Upload Icon",
  //         upload_icon_size: "Icon Size(13.83 X 18.5) (JPG, JPEG, PNG, WEBP)",
  //         upload_button_text: "Upload",
  //         section_position_label: "Section Position",
  //         section_position_left: "Left",
  //         section_position_right: "Right",
  //         description_label: "Description",
  //         section_order_label: "Order",
  //         submit_button_text: "Submit",
  //         update_button_text: "Update",
  //       },
  //       section_item_listing: {
  //         title: "Section Items",
  //         add_item_button: "Add Section Item",
  //         action_table_head: "ACTION",
  //         icon_table_head: "ICON",
  //         section_title_table_head: "SECTION TITLE",
  //         status_table_head: "STATUS",
  //         active: "Active",
  //         inactive: "Inactive",
  //         edit_menu_option: "Edit",
  //         delete_menu_option: "Delete",
  //         delete_confirmation_text:
  //           "Are you sure you want to delete this section item?",
  //       },
  //       add_edit_section_item: {
  //         add_item_title: "Add Section Item",
  //         edit_item_title: "Edit Section Item",
  //         section_item_title_label: "Section Item Title",
  //         section_item_status_label: "Section Item Status",
  //         section_item_status_active: "Active",
  //         section_item_status_inactive: "Inactive",
  //         upload_icon_label: "Upload Icon",
  //         upload_icon_size: "Image Size(1000 X 670) (JPG, JPEG, PNG , WEBP)",
  //         upload_button_text: "UPLOAD",
  //         buttom_text_label: "Button Text",
  //         buttom_link_label: "Button Link",
  //         description_label: "Description",
  //         submit_button_text: "Submit",
  //         update_button_text: "Update",
  //       },
  //       community_settings: {
  //         title: "Community Settings",
  //         allow_likes_label: "Allow Likes",
  //         allow_likes_text: "Likes visibility for members.",
  //         allow_comments_label: "Allow Comments",
  //         allow_comments_text: "Comments visibility for members.",
  //         allow_post_label: "Allow Post",
  //         allow_post_text: "Favourite Posts for members.",
  //         favorite_post_label: "Favorite Posts",
  //         favorite_post_text: "Favorite posts for members.",
  //         auto_post_approval_on: "Auto Post Approval Is On",
  //         auto_post_approval_off: "Auto Post Approval Is Off",
  //         auto_post_approval_text: "Post approval of members.",
  //         upload_banner_image_label: "Upload Banner Image",
  //         upload_banner_image_size:
  //           "Image Size(1000 X 670) ('JPG', 'JPEG', 'PNG', 'WEBP')",
  //         upload_button_text: "UPLOAD",
  //         select_banner_color_label: "Select Banner Color",
  //         favorite_posts_position_label: "Favorite Posts Position",
  //         favorite_posts_position_left_label: "Left",
  //         favorite_posts_position_right_label: "Right",
  //         left_content_label: "Left Content",
  //         right_content_label: "Right Content",
  //         center_content_label: "Center Content",
  //         update_button_text: "UPDATE",
  //         updating_button_text: "UPDATING...",
  //       },
  //     },
  //     programme_community: {
  //       left_community_section: {
  //         create_post_button_text: "Create Post",
  //         dashboard_tab_text: "Dashboard",
  //         approval_queue_tab_text: "Approval Queue",
  //         recent_posts_tab_text: "Recent Posts",
  //         pined_posts_tab_text: "Pined Posts",
  //         reported_posts_tab_text: "Reported Posts",
  //         trending_post_tab_text: "Trending Posts",
  //         manage_sidebar_sections_tab_text: "Manage Sidebar Sections",
  //         community_settings_tab_text: "Community Settings",
  //       },
  //       right_community_section: {
  //         today_button_text: "Today",
  //         last_seven_days_button_text: "Last 7 Days",
  //         last_thirty_days_button_text: "Last 30 Days",
  //         likes_card_title: "Likes",
  //         likes_card_decrease_text: "Decrease",
  //         likes_card_increase_text: "Increase",
  //         comments_card_title: "Comments",
  //         comment_card_decrease_text: "Decrease",
  //         comment_card_increase_text: "Increase",
  //         total_posts_card_title: "Total Posts",
  //         total_posts_decrease_text: "Decrease",
  //         total_posts_increase_text: "Increase",
  //         pined_posts_title: "Pined Posts",
  //         approval_queue_title: "Approval Queue",
  //         trending_posts_title: "Trending Posts",
  //         recent_posts_title: "Recent Posts",
  //         reported_posts_title: "Reported Posts",
  //         notification_card_title: "Notifications",
  //         recent_comments_card_title: "Recent Comments",
  //         unpin_posts_menu_option: "Unpin",
  //         delet_confirmation_text: "Are you sure you want to delete this Post?",
  //       },
  //       create_post_card: {
  //         create_post_button__text: "Create Post",
  //         create_post_heading: "Create Post",
  //         update_post_heading: "Update Post",
  //         whats_on_your_mind_placeholder: "What's on your mind,",
  //         general_feed_tooltip: "General Feed",
  //         image_feed_tooltip: "Image Feed",
  //         video_feed_tooltip: "Video Feed",
  //         embeded_code_feed_tooltip: "Embeded Feed",
  //         choose_background_color_tooltip: "Choose Background Color",
  //         add_photo_placeholder: "Add Photo",
  //         video_url_placeholder: "Video Url",
  //         embeded_code_feed_placeholder: "LiveStream Embeded Code",
  //         post_button_text: "Post",
  //         posting_button_text: "Posting...",
  //         cancel_button_text: "Cancel",
  //         update_button_text: "Update",
  //         updating_button_text: "Updating...",
  //       },
  //       share_your_thoughs_card: {
  //         share_your_thoughts_placeholder: "Share your thoughts,",
  //         photos_text: "Photos",
  //         videos_text: "Videos",
  //       },
  //       post_card: {
  //         edit_menu_text: "Edit",
  //         delete_menu_text: "Delete",
  //         pin_menu_text: "Pin",
  //         unpin_menu_text: "Unpin",
  //         message_menu_text: "Message",
  //         delete_confirmation_text:
  //           "Are you sure you want to delete this post?",
  //         see_more_button_text: "See More",
  //         see_less_button_text: "See Less",
  //         like_button_text: "Like",
  //         liked_button_text: "Liked",
  //         comment_button_text: "Comment",
  //       },
  //       feed_detail: {
  //         see_more_button_text: "See More",
  //         see_less_button_text: "See Less",
  //         like_button_text: "Like",
  //         liked_button_text: "Liked",
  //         comment_button_text: "Comment",
  //         and: "and",
  //         others: "others",
  //         write_a_comment_placeholder: "Write a comment…",
  //         edit_menu_text: "Edit",
  //         delete_menu_text: "Delete",
  //         comment_confirmation_text:
  //           "Are you sure you want to delete this comment?",
  //         comment_like_text: "Like",
  //         comment_liked_text: "Liked",
  //         comment_reply_text: "Reply",
  //         write_a_reply_placeholder: "Write a reply",
  //         single_comment_confirmation_menu_text:
  //           "Are you sure you want to delete this comment?",
  //       },
  //       section_listing: {
  //         title: "Section",
  //         add_section_button_text: "Add Section",
  //         action_table_head: "ACTION",
  //         icon_table_head: "ICON",
  //         section_title_table_head: "SECTION TITLE",
  //         position_table_head: "POSITION",
  //         order_table_head: "ORDER",
  //         status_table_head: "STATUS",
  //         active: "Active",
  //         inactive: "Inactive",
  //         edit_menu_option: "Edit",
  //         delete_menu_option: "Delete",
  //         section_items_menu_option: "Section Items",
  //         delete_confirmation_text:
  //           "Are you sure you want to delete this section?",
  //       },
  //       add_edit_section: {
  //         add_section_title: "Add Section",
  //         edit_section_title: "Edit Section",
  //         section_title_label: "Section Title",
  //         section_status_label: "Section Status",
  //         section_status_active: "Active",
  //         section_status_inactive: "Inactive",
  //         upload_icon_label: "Upload Icon",
  //         upload_icon_size: "Icon Size(13.83 X 18.5) (JPG, JPEG, PNG, WEBP)",
  //         upload_button_text: "Upload",
  //         section_position_label: "Section Position",
  //         section_position_left: "Left",
  //         section_position_right: "Right",
  //         description_label: "Description",
  //         section_order_label: "Order",
  //         submit_button_text: "Submit",
  //         update_button_text: "Update",
  //       },
  //       section_item_listing: {
  //         title: "Section Items",
  //         add_item_button: "Add Section Item",
  //         action_table_head: "ACTION",
  //         icon_table_head: "ICON",
  //         section_title_table_head: "SECTION TITLE",
  //         status_table_head: "STATUS",
  //         active: "Active",
  //         inactive: "Inactive",
  //         edit_menu_option: "Edit",
  //         delete_menu_option: "Delete",
  //         delete_confirmation_text:
  //           "Are you sure you want to delete this section item?",
  //       },
  //       add_edit_section_item: {
  //         add_item_title: "Add Section Item",
  //         edit_item_title: "Edit Section Item",
  //         section_item_title_label: "Section Item Title",
  //         section_item_status_label: "Section Item Status",
  //         section_item_status_active: "Active",
  //         section_item_status_inactive: "Inactive",
  //         upload_icon_label: "Upload Icon",
  //         upload_icon_size: "Image Size(1000 X 670) (JPG, JPEG, PNG , WEBP)",
  //         upload_button_text: "UPLOAD",
  //         buttom_text_label: "Button Text",
  //         buttom_link_label: "Button Link",
  //         description_label: "Description",
  //         submit_button_text: "Submit",
  //         update_button_text: "Update",
  //       },
  //       community_settings: {
  //         title: "Community Settings",
  //         allow_likes_label: "Allow Likes",
  //         allow_likes_text: "Likes visibility for members.",
  //         allow_comments_label: "Allow Comments",
  //         allow_comments_text: "Comments visibility for members.",
  //         allow_post_label: "Allow Post",
  //         allow_post_text: "Favourite Posts for members.",
  //         favorite_post_label: "Favorite Posts",
  //         favorite_post_text: "Favorite posts for members.",
  //         auto_post_approval_on: "Auto Post Approval Is On",
  //         auto_post_approval_off: "Auto Post Approval Is Off",
  //         auto_post_approval_text: "Post approval of members.",
  //         upload_banner_image_label: "Upload Banner Image",
  //         upload_banner_image_size:
  //           "Image Size(1000 X 670) ('JPG', 'JPEG', 'PNG', 'WEBP')",
  //         upload_button_text: "UPLOAD",
  //         select_banner_color_label: "Select Banner Color",
  //         favorite_posts_position_label: "Favorite Posts Position",
  //         favorite_posts_position_left_label: "Left",
  //         favorite_posts_position_right_label: "Right",
  //         left_content_label: "Left Content",
  //         right_content_label: "Right Content",
  //         center_content_label: "Center Content",
  //         update_button_text: "UPDATE",
  //         updating_button_text: "UPDATING...",
  //       },
  //     },
  //     lesson_community: {
  //       left_community_section: {
  //         create_post_button_text: "Create Post",
  //         dashboard_tab_text: "Dashboard",
  //         approval_queue_tab_text: "Approval Queue",
  //         recent_posts_tab_text: "Recent Posts",
  //         pined_posts_tab_text: "Pined Posts",
  //         reported_posts_tab_text: "Reported Posts",
  //         trending_post_tab_text: "Trending Posts",
  //         manage_sidebar_sections_tab_text: "Manage Sidebar Sections",
  //         community_settings_tab_text: "Community Settings",
  //       },
  //       right_community_section: {
  //         today_button_text: "Today",
  //         last_seven_days_button_text: "Last 7 Days",
  //         last_thirty_days_button_text: "Last 30 Days",
  //         likes_card_title: "Likes",
  //         likes_card_decrease_text: "Decrease",
  //         likes_card_increase_text: "Increase",
  //         comments_card_title: "Comments",
  //         comment_card_decrease_text: "Decrease",
  //         comment_card_increase_text: "Increase",
  //         total_posts_card_title: "Total Posts",
  //         total_posts_decrease_text: "Decrease",
  //         total_posts_increase_text: "Increase",
  //         pined_posts_title: "Pined Posts",
  //         approval_queue_title: "Approval Queue",
  //         trending_posts_title: "Trending Posts",
  //         recent_posts_title: "Recent Posts",
  //         reported_posts_title: "Reported Posts",
  //         notification_card_title: "Notifications",
  //         recent_comments_card_title: "Recent Comments",
  //         unpin_posts_menu_option: "Unpin",
  //         delet_confirmation_text: "Are you sure you want to delete this Post?",
  //       },
  //       create_post_card: {
  //         create_post_button__text: "Create Post",
  //         create_post_heading: "Create Post",
  //         update_post_heading: "Update Post",
  //         whats_on_your_mind_placeholder: "What's on your mind,",
  //         general_feed_tooltip: "General Feed",
  //         image_feed_tooltip: "Image Feed",
  //         video_feed_tooltip: "Video Feed",
  //         embeded_code_feed_tooltip: "Embeded Feed",
  //         choose_background_color_tooltip: "Choose Background Color",
  //         add_photo_placeholder: "Add Photo",
  //         video_url_placeholder: "Video Url",
  //         embeded_code_feed_placeholder: "LiveStream Embeded Code",
  //         post_button_text: "Post",
  //         posting_button_text: "Posting...",
  //         cancel_button_text: "Cancel",
  //         update_button_text: "Update",
  //         updating_button_text: "Updating...",
  //       },
  //       share_your_thoughs_card: {
  //         share_your_thoughts_placeholder: "Share your thoughts,",
  //         photos_text: "Photos",
  //         videos_text: "Videos",
  //       },
  //       post_card: {
  //         edit_menu_text: "Edit",
  //         delete_menu_text: "Delete",
  //         pin_menu_text: "Pin",
  //         unpin_menu_text: "Unpin",
  //         message_menu_text: "Message",
  //         delete_confirmation_text:
  //           "Are you sure you want to delete this post?",
  //         see_more_button_text: "See More",
  //         see_less_button_text: "See Less",
  //         like_button_text: "Like",
  //         liked_button_text: "Liked",
  //         comment_button_text: "Comment",
  //       },
  //       feed_detail: {
  //         see_more_button_text: "See More",
  //         see_less_button_text: "See Less",
  //         like_button_text: "Like",
  //         liked_button_text: "Liked",
  //         comment_button_text: "Comment",
  //         and: "and",
  //         others: "others",
  //         write_a_comment_placeholder: "Write a comment…",
  //         edit_menu_text: "Edit",
  //         delete_menu_text: "Delete",
  //         comment_confirmation_text:
  //           "Are you sure you want to delete this comment?",
  //         comment_like_text: "Like",
  //         comment_liked_text: "Liked",
  //         comment_reply_text: "Reply",
  //         write_a_reply_placeholder: "Write a reply",
  //         single_comment_confirmation_menu_text:
  //           "Are you sure you want to delete this comment?",
  //       },
  //       section_listing: {
  //         title: "Section",
  //         add_section_button_text: "Add Section",
  //         action_table_head: "ACTION",
  //         icon_table_head: "ICON",
  //         section_title_table_head: "SECTION TITLE",
  //         position_table_head: "POSITION",
  //         order_table_head: "ORDER",
  //         status_table_head: "STATUS",
  //         active: "Active",
  //         inactive: "Inactive",
  //         edit_menu_option: "Edit",
  //         delete_menu_option: "Delete",
  //         section_items_menu_option: "Section Items",
  //         delete_confirmation_text:
  //           "Are you sure you want to delete this section?",
  //       },
  //       add_edit_section: {
  //         add_section_title: "Add Section",
  //         edit_section_title: "Edit Section",
  //         section_title_label: "Section Title",
  //         section_status_label: "Section Status",
  //         section_status_active: "Active",
  //         section_status_inactive: "Inactive",
  //         upload_icon_label: "Upload Icon",
  //         upload_icon_size: "Icon Size(13.83 X 18.5) (JPG, JPEG, PNG, WEBP)",
  //         upload_button_text: "Upload",
  //         section_position_label: "Section Position",
  //         section_position_left: "Left",
  //         section_position_right: "Right",
  //         description_label: "Description",
  //         section_order_label: "Order",
  //         submit_button_text: "Submit",
  //         update_button_text: "Update",
  //       },
  //       section_item_listing: {
  //         title: "Section Items",
  //         add_item_button: "Add Section Item",
  //         action_table_head: "ACTION",
  //         icon_table_head: "ICON",
  //         section_title_table_head: "SECTION TITLE",
  //         status_table_head: "STATUS",
  //         active: "Active",
  //         inactive: "Inactive",
  //         edit_menu_option: "Edit",
  //         delete_menu_option: "Delete",
  //         delete_confirmation_text:
  //           "Are you sure you want to delete this section item?",
  //       },
  //       add_edit_section_item: {
  //         add_item_title: "Add Section Item",
  //         edit_item_title: "Edit Section Item",
  //         section_item_title_label: "Section Item Title",
  //         section_item_status_label: "Section Item Status",
  //         section_item_status_active: "Active",
  //         section_item_status_inactive: "Inactive",
  //         upload_icon_label: "Upload Icon",
  //         upload_icon_size: "Image Size(1000 X 670) (JPG, JPEG, PNG , WEBP)",
  //         upload_button_text: "UPLOAD",
  //         buttom_text_label: "Button Text",
  //         buttom_link_label: "Button Link",
  //         description_label: "Description",
  //         submit_button_text: "Submit",
  //         update_button_text: "Update",
  //       },
  //       community_settings: {
  //         title: "Community Settings",
  //         allow_likes_label: "Allow Likes",
  //         allow_likes_text: "Likes visibility for members.",
  //         allow_comments_label: "Allow Comments",
  //         allow_comments_text: "Comments visibility for members.",
  //         allow_post_label: "Allow Post",
  //         allow_post_text: "Favourite Posts for members.",
  //         favorite_post_label: "Favorite Posts",
  //         favorite_post_text: "Favorite posts for members.",
  //         auto_post_approval_on: "Auto Post Approval Is On",
  //         auto_post_approval_off: "Auto Post Approval Is Off",
  //         auto_post_approval_text: "Post approval of members.",
  //         upload_banner_image_label: "Upload Banner Image",
  //         upload_banner_image_size:
  //           "Image Size(1000 X 670) ('JPG', 'JPEG', 'PNG', 'WEBP')",
  //         upload_button_text: "UPLOAD",
  //         select_banner_color_label: "Select Banner Color",
  //         favorite_posts_position_label: "Favorite Posts Position",
  //         favorite_posts_position_left_label: "Left",
  //         favorite_posts_position_right_label: "Right",
  //         left_content_label: "Left Content",
  //         right_content_label: "Right Content",
  //         center_content_label: "Center Content",
  //         update_button_text: "UPDATE",
  //         updating_button_text: "UPDATING...",
  //       },
  //     },
  //     portal_community: {
  //       left_community_section: {
  //         create_post_button_text: "Create Post",
  //         dashboard_tab_text: "Dashboard",
  //         approval_queue_tab_text: "Approval Queue",
  //         recent_posts_tab_text: "Recent Posts",
  //         pined_posts_tab_text: "Pined Posts",
  //         reported_posts_tab_text: "Reported Posts",
  //         trending_post_tab_text: "Trending Posts",
  //         manage_sidebar_sections_tab_text: "Manage Sidebar Sections",
  //         community_settings_tab_text: "Community Settings",
  //       },
  //       right_community_section: {
  //         today_button_text: "Today",
  //         last_seven_days_button_text: "Last 7 Days",
  //         last_thirty_days_button_text: "Last 30 Days",
  //         likes_card_title: "Likes",
  //         likes_card_decrease_text: "Decrease",
  //         likes_card_increase_text: "Increase",
  //         comments_card_title: "Comments",
  //         comment_card_decrease_text: "Decrease",
  //         comment_card_increase_text: "Increase",
  //         total_posts_card_title: "Total Posts",
  //         total_posts_decrease_text: "Decrease",
  //         total_posts_increase_text: "Increase",
  //         pined_posts_title: "Pined Posts",
  //         approval_queue_title: "Approval Queue",
  //         trending_posts_title: "Trending Posts",
  //         recent_posts_title: "Recent Posts",
  //         reported_posts_title: "Reported Posts",
  //         notification_card_title: "Notifications",
  //         recent_comments_card_title: "Recent Comments",
  //         unpin_posts_menu_option: "Unpin",
  //         delet_confirmation_text: "Are you sure you want to delete this Post?",
  //       },
  //       create_post_card: {
  //         create_post_button__text: "Create Post",
  //         create_post_heading: "Create Post",
  //         update_post_heading: "Update Post",
  //         whats_on_your_mind_placeholder: "What's on your mind,",
  //         general_feed_tooltip: "General Feed",
  //         image_feed_tooltip: "Image Feed",
  //         video_feed_tooltip: "Video Feed",
  //         embeded_code_feed_tooltip: "Embeded Feed",
  //         choose_background_color_tooltip: "Choose Background Color",
  //         add_photo_placeholder: "Add Photo",
  //         video_url_placeholder: "Video Url",
  //         embeded_code_feed_placeholder: "LiveStream Embeded Code",
  //         post_button_text: "Post",
  //         posting_button_text: "Posting...",
  //         cancel_button_text: "Cancel",
  //         update_button_text: "Update",
  //         updating_button_text: "Updating...",
  //       },
  //       share_your_thoughs_card: {
  //         share_your_thoughts_placeholder: "Share your thoughts,",
  //         photos_text: "Photos",
  //         videos_text: "Videos",
  //       },
  //       post_card: {
  //         edit_menu_text: "Edit",
  //         delete_menu_text: "Delete",
  //         pin_menu_text: "Pin",
  //         unpin_menu_text: "Unpin",
  //         message_menu_text: "Message",
  //         delete_confirmation_text:
  //           "Are you sure you want to delete this post?",
  //         see_more_button_text: "See More",
  //         see_less_button_text: "See Less",
  //         like_button_text: "Like",
  //         liked_button_text: "Liked",
  //         comment_button_text: "Comment",
  //       },
  //       feed_detail: {
  //         see_more_button_text: "See More",
  //         see_less_button_text: "See Less",
  //         like_button_text: "Like",
  //         liked_button_text: "Liked",
  //         comment_button_text: "Comment",
  //         and: "and",
  //         others: "others",
  //         write_a_comment_placeholder: "Write a comment…",
  //         edit_menu_text: "Edit",
  //         delete_menu_text: "Delete",
  //         comment_confirmation_text:
  //           "Are you sure you want to delete this comment?",
  //         comment_like_text: "Like",
  //         comment_liked_text: "Liked",
  //         comment_reply_text: "Reply",
  //         write_a_reply_placeholder: "Write a reply",
  //         single_comment_confirmation_menu_text:
  //           "Are you sure you want to delete this comment?",
  //       },
  //       section_listing: {
  //         title: "Section",
  //         add_section_button_text: "Add Section",
  //         action_table_head: "ACTION",
  //         icon_table_head: "ICON",
  //         section_title_table_head: "SECTION TITLE",
  //         position_table_head: "POSITION",
  //         order_table_head: "ORDER",
  //         status_table_head: "STATUS",
  //         active: "Active",
  //         inactive: "Inactive",
  //         edit_menu_option: "Edit",
  //         delete_menu_option: "Delete",
  //         section_items_menu_option: "Section Items",
  //         delete_confirmation_text:
  //           "Are you sure you want to delete this section?",
  //       },
  //       add_edit_section: {
  //         add_section_title: "Add Section",
  //         edit_section_title: "Edit Section",
  //         section_title_label: "Section Title",
  //         section_status_label: "Section Status",
  //         section_status_active: "Active",
  //         section_status_inactive: "Inactive",
  //         upload_icon_label: "Upload Icon",
  //         upload_icon_size: "Icon Size(13.83 X 18.5) (JPG, JPEG, PNG, WEBP)",
  //         upload_button_text: "Upload",
  //         section_position_label: "Section Position",
  //         section_position_left: "Left",
  //         section_position_right: "Right",
  //         description_label: "Description",
  //         section_order_label: "Order",
  //         submit_button_text: "Submit",
  //         update_button_text: "Update",
  //       },
  //       section_item_listing: {
  //         title: "Section Items",
  //         add_item_button: "Add Section Item",
  //         action_table_head: "ACTION",
  //         icon_table_head: "ICON",
  //         section_title_table_head: "SECTION TITLE",
  //         status_table_head: "STATUS",
  //         active: "Active",
  //         inactive: "Inactive",
  //         edit_menu_option: "Edit",
  //         delete_menu_option: "Delete",
  //         delete_confirmation_text:
  //           "Are you sure you want to delete this section item?",
  //       },
  //       add_edit_section_item: {
  //         add_item_title: "Add Section Item",
  //         edit_item_title: "Edit Section Item",
  //         section_item_title_label: "Section Item Title",
  //         section_item_status_label: "Section Item Status",
  //         section_item_status_active: "Active",
  //         section_item_status_inactive: "Inactive",
  //         upload_icon_label: "Upload Icon",
  //         upload_icon_size: "Image Size(1000 X 670) (JPG, JPEG, PNG , WEBP)",
  //         upload_button_text: "UPLOAD",
  //         buttom_text_label: "Button Text",
  //         buttom_link_label: "Button Link",
  //         description_label: "Description",
  //         submit_button_text: "Submit",
  //         update_button_text: "Update",
  //       },
  //       community_settings: {
  //         title: "Community Settings",
  //         allow_likes_label: "Allow Likes",
  //         allow_likes_text: "Likes visibility for members.",
  //         allow_comments_label: "Allow Comments",
  //         allow_comments_text: "Comments visibility for members.",
  //         allow_post_label: "Allow Post",
  //         allow_post_text: "Favourite Posts for members.",
  //         favorite_post_label: "Favorite Posts",
  //         favorite_post_text: "Favorite posts for members.",
  //         auto_post_approval_on: "Auto Post Approval Is On",
  //         auto_post_approval_off: "Auto Post Approval Is Off",
  //         auto_post_approval_text: "Post approval of members.",
  //         upload_banner_image_label: "Upload Banner Image",
  //         upload_banner_image_size:
  //           "Image Size(1000 X 670) ('JPG', 'JPEG', 'PNG', 'WEBP')",
  //         upload_button_text: "UPLOAD",
  //         select_banner_color_label: "Select Banner Color",
  //         favorite_posts_position_label: "Favorite Posts Position",
  //         favorite_posts_position_left_label: "Left",
  //         favorite_posts_position_right_label: "Right",
  //         left_content_label: "Left Content",
  //         right_content_label: "Right Content",
  //         center_content_label: "Center Content",
  //         update_button_text: "UPDATE",
  //         updating_button_text: "UPDATING...",
  //       },
  //     },
  //   },
  // });
  const [defaulLanguagae, setDefaultLanguage] = useState(get_default_language);
  console.log(defaultContent, "defaultContentttttttttttt");
  const [isLnaguageChange, setIsLanguageChange] = useState(false);
  const [languageListing, setLanguageListing] = useState([]);
  const [isLanguageUpdating, setIsLanguageUpdating] = useState(false);

  /* ------------------------------------------------------
  ------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  const handleAdminTimeZone = (val) => {
    setAdminTimeZone(val);
  };

  const handleCoachTimeZone = (val) => {
    setCoachTimeZone(val);
  };

  const handleSetUserInfo = (val) => {
    // setUserInfo(val);
  };
  const handleSetCard = (val) => {
    setCardDetail(val);
  };
  const handleConsultantInfo = (val) => {
    // console.log(val, "sajgdsaud");
    setConsultantInfo(val);
  };
  const handleCoachList = (val) => {
    setCoachList(val);
  };
  const handleGeneralData = (val) => {
    // console.log(val, "sajgdsaud");
    setGeneralData(val);
  };
  const handleProjectData = (val) => {
    // console.log(val, "sajgdsaud");
    setProjectData(val);
  };
  const handleDomainData = (val) => {
    // console.log(val, "sajgdsaud");
    setDoaminData(val);
  };
  const handleCoachInfo = (val) => {
    setCoachInfo(val);
  };

  const handleConsultantData = (val) => {
    setConsultantData(val);
    setUserInfo(val?.coach);
  };
  const handleRemoveConsultantMetaData = () => {
    let configurationObj = {
      ...consultantData.configuration,
      meta_data: {
        ...consultantData.configuration.meta_data,
        meta_description: "",
        meta_title: "",
        favicon: {
          ...consultantData.configuration.meta_data.favicon,
          thumbnail_1: "",
        },
      },
    };
    setConsultantData((prev) => ({
      ...prev,
      configuration: configurationObj,
    }));
  };
  const handleDynamicCoach = (val) => {
    setDynamicCoachId(val);
  };
  const handleNavItmes = (val) => {
    setNavItems(val);
  };
  const handleCoachId = (val) => {
    setCoachId(val);
  };

  const handleGraphics = (val) => {
    set_graphics_data_in_localStorage(val);
    setCoachGraphics(val);
  };

  const getCoachLanguageListing = async () => {
    try {
      let result = await get_coach_language_list(coachId);
      if (result.code === 200) {
        // console.log(result, "resultOfdashboardlistong");
        setLanguageListing(result.languages_list);
        setIsLanguageUpdating(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Error fetching languages", { variant: "error" });
    }
  };

  useEffect(() => {
    getCoachLanguageListing();
  }, []);

  // console.log(handleCoachInfo, "gycsgfusdguifhduisfh");
  const getProfile = async () => {
    const result = await consultantProfileApi(userInfo?._id);
    if (result.code == 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result.consultant));
      localStorage.setItem(
        `plan_currency`,
        JSON.stringify(result.plan_currency)
      );
      localStorage.setItem(`admin_time_zone`, JSON.stringify(result.time_zone));
      handleSetUserInfo(result.consultant);
      handleAdminTimeZone(result.admin_time_zone);
    }
  };
  const getCardDetail = async () => {
    const postData = {
      coach_id: dynamicCoachId,
    };
    const result = await consultantDataApi(postData);

    if (result.code === 200) {
      // setCoachData(result.default_setting);
      // handleCoachInfo(result.default_setting);

      if (result?.configuration && result?.configuration.coach_card) {
        localStorage.setItem(
          `card_data`,
          JSON.stringify(result?.configuration.coach_card)
        );
        setCardDetail(result?.configuration.coach_card);
      }
    }
  };

  const getUserInfoDetails = async () => {
    const postData = {
      coach_id: dynamicCoachId,
    };
    const result = await consultantDataApi(postData);
    console.log(result, "configurationApiResponse");
    if (result.code === 200) {
      setIsLanguageChange(false);
      localStorage.setItem("consultantdata", JSON.stringify(result));
      localStorage.setItem(
        "coachId",
        JSON.stringify(result?.configuration.coach_id)
      );
      localStorage.setItem("coachList", JSON.stringify(result.coach_array));
      localStorage.setItem(
        `admin_time_zone`,
        JSON.stringify(result.configuration.admin_timezone)
      );
      localStorage.setItem(
        `coach_time_zone`,
        JSON.stringify(result.configuration.time_zone)
      );
      const coachObject = result.coach_array.find(
        (coach) => coach.coach_id === dynamicCoachId
      );
      const navItems = coachObject.nav_items;
      localStorage.setItem("navItems", JSON.stringify(navItems));
      localStorage.setItem(
        "defaultContent",
        JSON.stringify(result?.coach_static_content)
      );
      localStorage.setItem(
        "defaulLanguagae",
        JSON.stringify(result?.configuration?.default_language)
      );
      setDefaultLanguage(result?.configuration?.default_language);
      setDefaultContent(result?.coach_static_content);
      handleConsultantData(result);
      handleNavItmes(navItems);
      handleCoachId(result?.configuration.coach_id);
      handleCoachList(result.coach_array);
      handleGraphics(result?.configuration.coach_dummy_content);
      START_SOCKET_FORCEFULLY(result.coach._id);
      handleAdminTimeZone(result.configuration.admin_timezone);
      handleCoachTimeZone(result.configuration.time_zone);
      getCurrencyList();
    }
  };

  const handleGetProjectData = async () => {
    const resp = await project_configuration_api(coachId);
    if (resp.code === 200) {
      localStorage.setItem(
        "project_data",
        JSON.stringify(resp.configuration.project_configuration)
      );
      if (
        resp.configuration.project_configuration.stripe_configuration
          .public_key ||
        resp.configuration.project_configuration.stripe_configuration
          .sandbox_public_key
      ) {
        setStripeKeysExist(true);
      }
      handleProjectData(resp.configuration.project_configuration);
    }
    return resp;
  };

  const getCurrencyList = async () => {
    let result = await currencyApi();
    if (result.code == 200) {
      setCurrencyList(result.currency_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const convertedTimeInUserTimeZone = (dateString, format = "") => {
    return get_date_with_user_time_zone(
      dateString,
      format ?? "YYYY-MM-DD HH:mm:ss",
      coachTimeZone,
      adminTimeZone
    );
  };

  const convertedTimeInUserTimeZoneFromNow = (dateString) => {
    return moment(
      get_date_with_user_time_zone(
        dateString,
        "YYYY-MM-DD HH:mm:ss",
        coachTimeZone,
        adminTimeZone
      )
    ).fromNow();
  };

  const convertedTimeInUserTimeZoneFromCalender = (dateString) => {
    return moment(
      get_date_with_user_time_zone(
        dateString,
        "YYYY-MM-DD HH:mm:ss",
        coachTimeZone,
        adminTimeZone
      )
    ).calendar();
  };

  const collection = {
    coachList,
    setCoachList,
    handleCoachList,
    navItems,
    setNavItems,
    handleNavItmes,
    dynamicCoachId,
    setDynamicCoachId,
    handleDynamicCoach,
    consultantData,
    handleConsultantData,
    handleRemoveConsultantMetaData,
    generalData,
    projectData,
    handleProjectData,
    domainData,
    handleDomainData,
    handleGetProjectData,
    stripeKeysExist,
    setStripeKeysExist,
    handleGeneralData,
    handleCoachId,
    coachId,
    coachInfo,
    handleCoachInfo,
    adminTimeZone,
    setAdminTimeZone,
    coachTimeZone,
    setCoachTimeZone,
    handleCoachTimeZone,
    handleSetUserInfo,
    userInfo,
    getUserInfoDetails,
    teamCoachId,
    getProfile,
    getCardDetail,
    consultantId,
    teamType,
    memberType,
    setUserInfo,
    consultantInfo,
    cardDetail,
    handleSetCard,
    setCardDetail,
    handleConsultantInfo,
    // currency List
    currencyList,
    setCurrencyList,
    getCurrencyList,

    handleAdminTimeZone,
    convertedTimeInUserTimeZoneFromNow,
    convertedTimeInUserTimeZoneFromCalender,
    convertedTimeInUserTimeZone,
    socket,
    START_SOCKET_FORCEFULLY,
    STOP_SOCKET_FORCEFULLY,
    openCardModal,
    setOpenCardModal,
    handleGraphics,
    coachGraphics,
    open,
    setOpen,
    defaultContent,
    setDefaultContent,
    isLnaguageChange,
    setIsLanguageChange,
    defaulLanguagae,
    setDefaultLanguage,
    languageListing,
    setLanguageListing,
    getCoachLanguageListing,
    isLanguageUpdating,
    setIsLanguageUpdating,
  };

  useEffect(() => {
    // console.log(socket, "--socket");
    if (
      userInfo?._id &&
      !isConnected &&
      typeof socket === "object" &&
      Object.keys(socket).length > 0
    ) {
      socket.emit("live_event_room", userInfo._id);
      setIsConnected(true);
    }
    return () => {};
  }, [userInfo, isConnected]);

  // useEffect(() => {
  //   userInfo.is_executive = true;
  // }, [userInfo]);
  // console.log(socket, "i am socket");
  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
